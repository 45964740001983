<template lang="pug">
  .profile.pt-5.pb-3(ref="profileWrapper")
    .st-container
      .kt-profile
        .kt-profile__main
          .profile__container
            profile-header.mb-5(:userInfo="userInfo")
            ul.kt-pills.nav.nav-pills.mb-5
              li.nav-item(
                v-for="item in tabs"
                :key="item.id"
                @click="selectTab(item.id)")
                .nav-link(
                  :class="{ active: item.id === selectedTab }")
                  | {{ item.title | translate }}
            .kt-form
              .kt-portlet__body
                profile-general(v-if="selectedTab === 0"
                  :userInfoForm="userInfoForm"
                  :isOwnProfileEditingPermitted="isOwnProfileEditingPermitted")
                profile-details(v-if="selectedTab === 1"
                  :userInfoForm="userInfoForm"
                  :isOwnProfileEditingPermitted="isOwnProfileEditingPermitted")
                profile-settings(v-if="selectedTab === 2"
                  :userInfoForm="userInfoForm"
                  @on-save-language="applyChanges")
            .tab-pane.kt-portlet__form-buttons.position-right
              v-btn(
                v-if="selectedTab !== 2"
                :loading="loading"
                @click="applyChanges") {{ 'ui.buttons.apply' | translate }}
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

import ProfileHeader from './ProfileHeader'
import ProfileGeneral from './form/General'
import ProfileDetails from './form/Details'
import ProfileSettings from './form/Settings'

export default {
  name: 'Profile',

  components: {
    ProfileHeader,
    ProfileGeneral,
    ProfileDetails,
    ProfileSettings
  },

  async mounted () {
    if (this.userInfo.id !== undefined) {
      this.init()
    }
    this.clearErrors()
  },

  destroyed () {
    this.clearErrors()
    this.setShowAlert(false)
  },

  data: () => ({
    tabs: [
      { id: 0, title: 'pages.profile.general' },
      { id: 1, title: 'pages.profile.details' },
      { id: 2, title: 'pages.profile.settings' }
    ],
    selectedTab: 0,
    userInfoForm: {
      firstName: '',
      lastName: '',
      email: '',
      description: '',
      phoneNumber: '',
      department: {},
      location: {},
      position: {},
      language: {}
    },
    loading: false,
    showAlert: false
  }),

  methods: {
    ...mapActions('app', [
      'updateUserInfo',
      'clearErrors',
      'setPopupMessage'
    ]),

    init () {
      for (const field in this.userInfoForm) {
        this.userInfoForm[field] = this.userInfo[field]
      }
    },

    selectTab (index) {
      this.setShowAlert(false)
      this.selectedTab = index
    },

    async applyChanges () {
      this.loading = true
      this.clearErrors()
      let data = {}
      if (!this.isOwnProfileEditingPermitted) {
        data = {
          description: this.userInfoForm.description,
          phoneNumber: this.userInfoForm.phoneNumber,
          languageId: this.userInfoForm.language.id
        }
      } else {
        data = {
          firstName: this.userInfoForm.firstName,
          lastName: this.userInfoForm.lastName,
          email: this.userInfoForm.email,
          description: this.userInfoForm.description,
          phoneNumber: this.userInfoForm.phoneNumber,
          departmentId: this.userInfoForm.department.id,
          locationId: this.userInfoForm.location.id,
          positionId: this.userInfoForm.position.id,
          languageId: this.userInfoForm.language.id
        }
      }
      const successfullUpdate = await this.updateUserInfo(data)
      this.loading = false
      if (successfullUpdate) {
        this.setShowAlert(true)
      } else {
        this.setShowAlert(false)
      }
    },

    setShowAlert (param) {
      if (param) {
        this.setPopupMessage({
          message: this.$t('pages.profile.changes_successfully_saved')
        })
      } else {
        this.setPopupMessage(null)
      }
    }
  },

  computed: {
    ...mapGetters('app', [
      'userInfo',
      'isOwnProfileEditingPermitted'
    ])
  },

  watch: {
    userInfo () {
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
  .profile {
    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 480px) {
    .kt-portlet__form-buttons {
      button {
        width: 100%;
      }
    }
  }
</style>
